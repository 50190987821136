import React, { useEffect, useRef, useState } from "react"
import SelectVehicle from "../UI/BookingForm/SelectStaff"
import SelectLocation from "../UI/BookingForm/SelectLocation"
import { useDispatch, useSelector } from "react-redux"
import { bookingAction } from "../store/actions/bookingAction"
import { openToastify } from "../store/actions/toastifyAction"
import { offerAction } from "../store/actions/offerAction"
import {
  REDUX_OFFER_DEFAULTS,
  REDUX_STORE_DEFAULTS,
  REDUX_TOASTIFY_DEFAULTS,
} from "../store/config"
import SelectService from "../UI/BookingForm/SelectService"
import SelectExtraService from "../UI/BookingForm/SelectExtraService"
import { BookingCalender } from "../UI/Calendar/BookingCalender"
import InformationForm from "../UI/BookingForm/InformationForm"
import SummarySection from "./sections/SummarySection"
import { AppointmentData } from "../../components/config/bookingDataConfig"
import BookingStepBtnSection from "./sections/BookingStepBtnSection"
import Confirmation from "../UI/BookingForm/Confirmation"
import SelectServiceCategory from "../UI/BookingForm/SelectServiceCategory"
import { getConnectServer } from "../services/api/apiModule"
import { collection, getDocs } from "firebase/firestore"
import { db } from "../../firebase-config"
import { Helmet } from "react-helmet-async"
import TagManager from "react-gtm-module"
import TsParticles from "../../context/TsParticles"

const Booking = () => {
  const dispatch = useDispatch()
  let {
    bookingStepsLabel,
    stepNo,
    location,
    staffCategory,
    service,
    bookingSlot,
    confirmBooking,
  } = useSelector((state) => state.bookingReducer)
  let { data } = useSelector((state) => state.offerReducer)
  const [offerData, setOfferData] = useState(data ?? {})

  // On location change the page go to next step
  useEffect(() => {
    setOfferData(data)
  }, [data])

  useEffect(() => {
    // bookingStepHander("NEXT")
    // console.log("here", location, bookingStepsLabel)
    //  console.log(
    //     "Checking steps",
    //     AppointmentData[location?.id]?.staff[staffCategory?.id]?.servicesList[
    //       service?.id
    //     ]?.services[service?.serviceId]?.addonService.length
    //   )
    window.scrollTo(0, 0)
  }, [stepNo])

  const booking = () => {
    switch (stepNo) {
      case 1:
        return <SelectLocation />

      case 2:
        return <SelectVehicle />

      case 3:
        return <SelectServiceCategory />

      case 4:
        return <SelectService />

      case 5:
        return <SelectExtraService />

      case 6:
        return <BookingCalender />

      case 7:
        return <InformationForm />

      // case 7:
      //   return <Confirmation />

      default:
        break
    }
  }

  const hitServerApi = async () => {
    try {
      const response = await getConnectServer()
      console.log(response.msg)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (stepNo === 6) {
      hitServerApi()
    }
  }, [stepNo])

  // const dispatch = useDispatch()
  const usersCollectionRef = collection(db, "offers")

  const getData = async () => {
    const allDocs = []
    const querySnapshot = await getDocs(usersCollectionRef)

    querySnapshot.forEach((doc) => {
      allDocs.push({ id: doc.id, ...doc.data() }) // Push document data and ID into the array
    })

    dispatch(offerAction(REDUX_OFFER_DEFAULTS.GET_OFFER(), allDocs[0]))
    console.log("allDocs[0]", allDocs[0])
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "G-W1M2KMXR47",
    }

    TagManager.initialize(tagManagerArgs)
    console.log("Gtag-initialized")
  }, [])

  const ReactHelmet = () => {
    switch (stepNo) {
      case 1:
        return (
          <Helmet>
            <title>Booking | Location</title>
            <link rel="canonical" href="https://boostmobiledetailing.ca/" />
          </Helmet>
        )

      case 2:
        return (
          <Helmet>
            <title>Booking | Vehicle</title>
            <link rel="canonical" href="https://boostmobiledetailing.ca/" />
          </Helmet>
        )

      case 3:
        return (
          <Helmet>
            <title>Booking | Service Category</title>
            <link rel="canonical" href="https://boostmobiledetailing.ca/" />
          </Helmet>
        )

      case 4:
        return (
          <Helmet>
            <title>Booking | Service</title>
            <link rel="canonical" href="https://boostmobiledetailing.ca/" />
          </Helmet>
        )

      case 5:
        return (
          <Helmet>
            <title>Booking | Add-On Service</title>
            <link rel="canonical" href="https://boostmobiledetailing.ca/" />
          </Helmet>
        )

      case 6:
        return (
          <Helmet>
            <title>Booking | Calender</title>
            <link rel="canonical" href="https://boostmobiledetailing.ca/" />
          </Helmet>
        )

      case 7:
        return (
          <Helmet>
            <title>Booking | Info Form</title>
            <link rel="canonical" href="https://boostmobiledetailing.ca/" />
          </Helmet>
        )

      default:
        break
    }
  }

  const [copySuccess, setCopySuccess] = useState("Copy")
  const textRef = useRef(null)

  const copyToClipboard = async () => {
    try {
      const textToCopy = textRef.current.innerText // Get the text content of the span
      await navigator.clipboard.writeText(textToCopy) // Copy the text to clipboard
      setCopySuccess("Copied!") // Update the button text after successful copy
      setTimeout(() => {
        setCopySuccess("Copy")
      }, 2000)
    } catch (err) {
      setCopySuccess("Failed to copy!") // Handle error if something goes wrong
    }
  }

  return (
    <>
      <ReactHelmet />
      {offerData && offerData.animation && offerData.display && (
        <div className="fixed inset-0 z-10 bg-black backdrop-blur-sm bg-opacity-55 flex justify-center items-center flex-col">
          <div className="bg- white h- [16rem] w-[95%] sm:w-[30%] relative flex flex-col justify-center items-center">
            <div className="flex justify-end -mt-2 -mr-3 z-10 w-full">
              <svg
                className="size-7 bg-white rounded-xl p-1 border-2 cursor-pointer"
                onClick={() => {
                  setOfferData((prev) => {
                    return { ...prev, animation: false }
                  })
                }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </div>

            <div className="size-[24rem] bg-black absolute rounded-3xl rotate-45 -z-10"></div>
            <div className="flex justify-center items-center rounded-xl flex-col h-full overflow-hidden">
              <img
                src={data?.imgUrl ?? ""}
                alt={data?.name}
                className="w-[26rem] h- [12rem] object-cover border-b-2"
                loading="lazy"
              />

              {offerData?.offerText && (
                <button
                  className="text- xs bg-black w-full text-center text-white z-20 h-12"
                  onClick={copyToClipboard}
                >
                  "Click Here To {copySuccess} This Code{" "}
                  <span ref={textRef} className="">
                    {offerData.offerText}
                  </span>
                  "
                </button>
              )}
            </div>
          </div>
          <TsParticles />
        </div>
      )}
      <div className="h-full w-full relative overflow-y-scroll allbooking_scroll overflow-x-hidden flex flex-col lg:flex-row items- center justify-between md:p-5">
        {/* <div
          className="w-[30rem] h-[30rem] rounded-full opacity-50 absolute -right-40"
          style={{
            background:
              "radial-gradient(#e9c46b -70%, #e9c46b -20%, transparent 70%)",
          }}
        ></div>
        <div
          className="w-[30rem] h-[30rem] rounded-full opacity-50 absolute -left-40 bottom-0"
          style={{
            background:
              "radial-gradient(#e9c46b -70%, #e9c46b -20%, transparent 70%)",
          }}
        ></div> */}

        {/* booking steps */}
        <div className="font-medium sm:w- [59%] flex-1 pt-5 space-y- 5 divide-y-2 divide-black sm:mx-2 flex flex-col justify-between">
          {/* Showing Steps numbers */}

          <div className="flex items-center justify-between sm:w- full pb-5">
            {bookingStepsLabel.map((step, index) => (
              <div key={index} className="flex items-center flex-col">
                <div
                  className={`w-6 h-6 flex items-center justify-center rounded-full border-2 border-gray-300 ${
                    index < stepNo
                      ? "bg-yellow-medium_dark"
                      : "bg-gray-300 text-black"
                  }`}
                >
                  {index + 1}
                </div>
                <div
                  className={`hidden lg:flex text-sm ${
                    index < stepNo - 1 ? "text-yellow-medium_dark" : ""
                  }`}
                >
                  {step.label}
                </div>
              </div>
            ))}
          </div>

          {/* Next step or back */}
          <BookingStepBtnSection />

          {/* Steps */}
          <div className="sm:w- full sm:h-full lg:overflow-y-scroll booking_scroll p-3">
            {booking()}
          </div>

          {/* Next step or back */}
          <BookingStepBtnSection />
        </div>

        {/* booking summary */}
        <SummarySection />
      </div>
    </>
  )
}

export default Booking
