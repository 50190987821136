import { Route, BrowserRouter as Router, Routes } from "react-router-dom"
import Home from "./components/pages/Home"
import Booking from "./components/pages/Booking"
import Navbar from "./components/UI/Header/Navbar"
import Footer from "./components/UI/Footer/Footer"
import { Provider } from "react-redux"
import store from "./components/store/Store"
import { HelmetProvider } from "react-helmet-async"

function App() {
  return (
    <>
      <HelmetProvider>
        <Provider store={store}>
          <div className="bg- black lg:h-screen text-black flex flex-col justify-between">
            <Navbar />
            <Router>
              <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/booking" element={<Booking />}></Route>
              </Routes>
            </Router>
            <Footer />
          </div>
        </Provider>
      </HelmetProvider>
    </>
  )
}

export default App
