import React, { useEffect, useRef, useState } from "react"
import logo from "../../asserts/boost-detailing-logo.png"
import { useDispatch, useSelector } from "react-redux"
import { bookingAction } from "../../store/actions/bookingAction"
import { REDUX_STORE_DEFAULTS } from "../../store/config"
import { AppointmentData } from "../../config/bookingDataConfig"
import _ from "lodash"

function SelectLocation() {
  const dispatch = useDispatch()
  const [selected, setSelected] = useState()
  let { totalSteps, stepNo, location } = useSelector(
    (state) => state.bookingReducer
  )
  let { data } = useSelector((state) => state.offerReducer)

  const submitHandler = (e) => {
    setSelected(e)
    // console.log("qq", e)

    const locationData = {
      id: e.id,
      description: e.description,
      displayName: e.displayName,
      locationDiscount: e.locationDiscount,
      locationRole: e.locationRole,
    }

    if (location.locationRole === e.locationRole) {
      dispatch(
        bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_LOCATION(), "")
      )
    } else {
      dispatch(
        bookingAction(
          REDUX_STORE_DEFAULTS.UPDATE_BOOKING_LOCATION(),
          locationData
        )
      )
      // setTimeout(() => {
      //   dispatch(
      //     bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_STEPS(), stepNo + 1)
      //   )
      // }, 100)
    }

    // dispatch(
    //   bookingAction(
    //     REDUX_STORE_DEFAULTS.UPDATE_DISCOUNT(),
    //     locationData.locationDiscount
    //   )
    // )
  }

  const [copySuccess, setCopySuccess] = useState("Copy")
  const textRef = useRef(null)

  const copyToClipboard = async () => {
    try {
      const textToCopy = textRef.current.innerText // Get the text content of the span
      await navigator.clipboard.writeText(textToCopy) // Copy the text to clipboard
      setCopySuccess("Copied!") // Update the button text after successful copy
      setTimeout(() => {
        setCopySuccess("Copy")
      }, 2000)
    } catch (err) {
      setCopySuccess("Failed to copy!") // Handle error if something goes wrong
    }
  }

  return (
    <>
      {/* location */}
      <div className="space-y-4 w-full">
        {/* <h1>Select Service Type</h1> */}
        <div className="space-y-4 sm:space-y-0 lg:space-x-4 flex flex-col lg:flex-row justify-center sm:justify-start ">
          {AppointmentData.map((value, key) => {
            return (
              <div
                key={key}
                className={` relative overflow-hidden cursor-pointer h-[12rem] sm:w-[11rem] flex flex-col items-center justify-center rounded-xl backdrop-blur-xl ${
                  selected === value.locationRole ||
                  location.locationRole === value.locationRole
                    ? "bg-yellow-medium_dark bg-opacity -35 shadow shadow-white"
                    : "border-2 border-yellow-light_medium"
                } `}
                onClick={() => {
                  submitHandler(value)
                }}
              >
                <img
                  src={value.imgUrl}
                  alt="location"
                  className="object-cover w-20"
                  loading="lazy"
                />
                <span className="capitalize mt-2">{value.displayName}</span>
                <span className="capitalize text-xs text-slate-600 opacity-80">
                  {value.description}
                </span>

                <div className="absolute left-0 top-0">
                  <div className=" bg-yellow-light text-white uppercase flex items-center justify-center rounded-br-xl shadow-md shadow-white overflow-hidden h-[1.5rem] w-32">
                    <span>{value.ribbon}</span>
                  </div>
                  {/* <div className="relative text-white uppercase flex items-center justify-center rounded-br-xl shadow-md shadow-white overflow-hidden h-[1.5rem] w-32">
                    <img
                      src="https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExYzU3dnM3a25pdHgxZG85aXBxd2d6Z2h3MTFxbWc3NnF5cmxrYWNmNSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/OK5LK5zLFfdm/giphy.webp"
                      alt="offer"
                      className="absolute -z-10 left-0 top-0 object-cover object-center h-[1.5rem] w-32"
                    />
                    <span>{value.ribbon}</span>
                  </div> */}
                </div>
              </div>
            )
          })}

          {data?.display ? (
            <div
              className={`relative border-2 border-yellow-light_medium overflow-hidden md:flex-1 flex flex-col items-center rounded-xl bg- yellow-medium_dark shadow shadow-white text-${data?.textColor}`}
            >
              <img
                src={data?.imgUrl ?? ""}
                alt={data?.name ?? "offer"}
                className="w-[16rem] object-contain"
                loading="lazy"
              />
              {/* <div className="px-5 py-3 h-[12rem] flex items-center justify-between w-full z-10">
                <div className="flex-1 w-1/2">
                  <h1 className="sm:text-3xl text-xl">{data?.name}</h1>
                  <p className="sm:text-base text-sm font-normal">
                    {_.truncate(data?.description, {
                      length: 50,
                      omission: "...",
                    })}
                  </p>
                </div>
                <div className="sm:text-4xl text-lg w-1/2 text-right">
                  <h1>{data?.offerText}</h1>
                </div>
              </div> */}

              {data?.offerText && (
                <button
                  className="text-sm bg-yellow-medium_dark w-full text-center text-white py-1"
                  onClick={copyToClipboard}
                >
                  "Click Here To {copySuccess} This Code{" "}
                  <span ref={textRef} className="">
                    {data.offerText}
                  </span>
                  "
                </button>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  )
}

export default SelectLocation
